<template>
  <v-app id="inspire">
    <v-app-bar color="deep-orange-darken-1">
   
      <v-spacer></v-spacer>
      <v-icon class="mx-2" @click="toggleTheme" icon="mdi-theme-light-dark"></v-icon>
      <template v-if="isAuthenticated">
        <v-menu v-model="menu" :close-on-content-click="false" max-width="400px">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" class="mx-2" icon="mdi-account-circle"></v-icon>
          </template>
          <v-card class="mt-0">
            <v-list>
              <v-list-item prepend-avatar="/user.png" :title="currentUser.name" :subtitle="currentUser.email">
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>             
              <v-btn color="blue" @click="logout">SALIR</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app color="deep-orange-darken-1" v-if="isAuthenticated">
      &#64;INTERNATIONAL SOS {{ new Date().getFullYear() }}
    </v-footer>
  </v-app>
</template>
<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

// Obtener el tema preferido del almacenamiento local al cargar la página
theme.global.name.value = localStorage.getItem('theme') || 'light'

function toggleTheme() {
  // Cambiar el tema
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'

  // Guardar la preferencia del tema en el almacenamiento local
  localStorage.setItem('theme', theme.global.name.value)
}
</script>
<script >
import { mapState } from 'vuex';
import { mapGetters } from "vuex";
export default {
  name: 'App',
  data: () => ({
    drawer: null,
    menu: false,
    locales: [
      { name: 'English', code: 'en' },
      { name: 'Spanish', code: 'es' }
    ]
  }),
  computed: {
    ...mapState(['snackbar', 'isLoading']),
    ...mapGetters(['currentUser', 'isAuthenticated'])
  },
  methods: {
    async logout() {
      this.$store.commit('SET_LOADING', true)
      try {
        await this.$store.dispatch('logout')
        await this.$router.push('/login')
        this.$store.commit('SET_LOADING', false)
      } catch (e) {
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
        this.$store.commit('SET_LOADING', false)
      }
    },
    async configuracion() {
      this.$router.push('usuario')
    }
  },
}
</script>