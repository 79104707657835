import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    cuestionarios: [],
    user: null,
    isLoading: false
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user
    },
    currentUser(state) {
      return { ...state.user }
    },
  },
  mutations: {
    SET_LOADING(state, show) {
      state.isLoading = show
    },
    SET_USER(state, data) {
      state.user = data
    },
    SET_CUESTIONARIOS(state, data) {
      state.cuestionarios = data
    }
  },
  actions: {
    async getUser({ commit }) {
      try {
        const { data } = await axios.get('/api/user')
        commit('SET_USER', data)
      } catch (e) {
        commit('SET_USER', null)
        throw new Error(await e.response.data.message)
      }
    },
    async login({ dispatch }, credentials) {
      try {
        await axios.post('/login', credentials)
        await dispatch('getUser')
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async register(_, credentials) {
      try {
        await axios.post('/register', credentials)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async logout({ commit }) {
      try {
        await axios.post('/logout')
        commit('SET_USER', null)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async fetchCuestionarios({ commit }, params) {
      const config = { params }
      try {
        const { data } = await axios.get(`/api/cuestionario`, config)
        commit('SET_CUESTIONARIOS', data)
        return data
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async reporteExcel(_, params) {
    
      try {
        await axios.post('/api/cuestionarioExport', params, {
          responseType: 'blob'
        })
          .then((response) => {
            const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'cuestionarios.xlsx')
            document.body.appendChild(link)
            link.click()
          });
      } catch (error) {
        console.log(error);
        throw new Error(await error.response.data.message);
      }
    },
  },
  modules: {
  }
})
