import { createRouter, createWebHashHistory } from 'vue-router'
import ListadoCuestionariosView from '@/views/ListadoCuestionariosView.vue'
import LoginView from "@/views/LoginView.vue";
import RegisterView from '@/views/RegisterView.vue';
import AppContainer from "@/AppContainer.vue";
import store from "../store";

const routes = [
/*   {
    path: '/',
    component: AppContainer,
    meta: { requiresAuth: true },
  }, */
  {
    path: '/',
    component: AppContainer,
    children: [
      {
        path: '',
        component: ListadoCuestionariosView,
        name: 'cuestionarios',
        meta: { requiresAuth: true }
      }/* ,
      {
        path: '/declaracion/:id',
        component: DetalleDeclaracion,
        name: 'declaracion',
        meta: { requiresAuth: true }
      } */
    ]
  },
  {
    path: '/login',
    component: LoginView,
    name: 'login'
  },
  {
    path: '/register',
    component: RegisterView,
    name: 'register'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next('/login')
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
