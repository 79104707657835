<template>
    <div>
        <v-card outlined class="mt-0">
            <v-spacer></v-spacer>
            <v-card-title class="text-h4 my-5 text-center">ENCUESTA DE FATIGA <v-btn variant="tonal" class="ml-2"
                    append-icon="mdi-download" color="green" @click="generarExcel()">Descargar</v-btn></v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-data-table :headers="headers" :items="cuestionarios" :loading="isLoading" hover sticky
                            height="700" class="elevation-0">
                            <template v-slot:[`header.p1`]="{ column }">
                                <v-tooltip text="¿Se siente usted somnoliento?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p2`]="{ column }">
                                <v-tooltip text="¿Siente la vista cansada? (párpados pesados, lagrimeo, visión borrosa)"
                                    location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p3`]="{ column }">
                                <v-tooltip text="¿Siente deseos de bostezar de manera frecuente?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p4`]="{ column }">
                                <v-tooltip text="¿Siente la cabeza aturdida/atontada?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p5`]="{ column }">
                                <v-tooltip text="¿Se siente poco firme o inseguro al estar de pie?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p6`]="{ column }">
                                <v-tooltip text="¿Siente que le falta energía?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p7`]="{ column }">
                                <v-tooltip text="¿Siente que movilizarse le requiere mayor esfuerzo del habitual?"
                                    location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p8`]="{ column }">
                                <v-tooltip text="¿Siente debilidad en algunas partes del cuerpo?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p9`]="{ column }">
                                <v-tooltip text="¿Se siente cansado, pesado y rígido?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p10`]="{ column }">
                                <v-tooltip text="¿Siente que no se puede concentrar?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p11`]="{ column }">
                                <v-tooltip text="¿Ha dormido menos de 6 horas las últimas 24 horas?" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p12`]="{ column }">
                                <v-tooltip
                                    text="¿Ha ingerido algún fármaco que pueda generarle sueño o disminuir sus reflejos como antialérgicos, relajantes musculares, antidepresivos, etc?"
                                    location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p13`]="{ column }">
                                <v-tooltip
                                    text="¿Durante su descanso ha ingerido bebidas alcohólicas en exceso y no ha pasado más de 15 horas?"
                                    location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`header.p14`]="{ column }">
                                <v-tooltip
                                    text="¿Ha realizado más actividad física de lo habitual, previo a su turno de trabajo?"
                                    location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ column.title }}</span>
                                    </template>
                                </v-tooltip>
                            </template>
                            <template v-slot:thead>
                                <tr>
                                    <td>
                                        <v-text-field v-model="params.queryNombres" hide-details type="text"
                                            class="ma-1" density="compact"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="params.queryApellidoPaterno" hide-details type="text"
                                            class="ma-1" density="compact"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="params.queryApellidoMaterno" hide-details type="text"
                                            class="ma-1" density="compact"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="params.queryDni" hide-details type="text" class="ma-1"
                                            density="compact"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="params.queryPuesto" hide-details type="text" class="ma-1"
                                            density="compact"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="params.querySupervisor" hide-details type="text"
                                            class="ma-1" density="compact"></v-text-field>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <v-text-field v-model="params.querySuma" min="0" hide-details type="number"
                                            class="ma-1" density="compact"></v-text-field>
                                    </td>
                                    <td colspan="2">
                                        <v-row>
                                            <v-col>
                                                <v-text-field density="compact" type="date" hide-details
                                                    v-model="params.queryFechaInicio"
                                                    label="Fecha Inicio"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field density="compact" type="date" hide-details
                                                    v-model="params.queryFechaFinal" label="Fecha Fin"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:[`item.persona.nombres`]="{ item }">
                                {{ item.persona ? item.persona.nombres.toUpperCase() : '' }}
                            </template>
                            <template v-slot:[`item.persona.apellido_p`]="{ item }">
                                {{ item.persona ? item.persona.apellido_p.toUpperCase() : '' }}
                            </template>
                            <template v-slot:[`item.persona.apellido_m`]="{ item }">
                                {{ item.persona ? item.persona.apellido_m.toUpperCase() : '' }}
                            </template>
                            <template v-slot:[`item.persona.puesto`]="{ item }">
                                {{ item.persona ? item.persona.puesto.toUpperCase() : '' }}
                            </template>
                            <template v-slot:[`item.supervisor`]="{ item }">
                                {{ item.supervisor ? item.supervisor.toUpperCase() : '' }}
                            </template>
                            <template v-slot:[`item.p12`]="{ item }">
                                <v-chip color="red">
                                    {{ item.p12 }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.suma`]="{ item }">
                                <v-chip color="green">
                                    {{ item.p1 + item.p2 + item.p3 + item.p4 + item.p5 + item.p6 + item.p7 + item.p8
                                        + item.p9 + item.p10 + item.p11 + item.p12 + item.p13 + item.p14 }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.fecha`]="{ item }">
                                {{ item.created_at ? moment(item.created_at).format('DD-MM-YYYY') : '' }}
                            </template>
                            <template v-slot:[`item.hora`]="{ item }">
                                {{ item.created_at ? moment(item.created_at).format('hh:mm A') : '' }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </div>
</template>
<script>
import moment from "moment";
export default {
    name: 'ListadoCuestionariosView',
    data() {
        return {
            itemsPorPagina: [
                { value: 10, title: '10' },
                { value: 25, title: '25' },
                { value: 50, title: '50' },
                { value: 100, title: '100' }
            ],
            moment,
            isLoading: false,
            params: {},
            options: {},
        }
    },

    components: {
    },
    methods: {
        async generarExcel() {
            this.params = {
                ...this.params,
                ...this.options
            }
            await this.$store.dispatch('reporteExcel', this.params)
        },
        async cargarData() {
            // this.isLoading = true
            this.params = {
                ...this.params,
                ...this.options
            }
            try {
                await this.$store.dispatch('fetchCuestionarios', this.params)
            } catch (e) {
                this.isLoading = false
                this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
            }
        }
    },
    computed: {
        headers() {
            return [
                { title: 'Nombres', align: 'start', sortable: false, key: 'persona.nombres', width: '10%' },
                { title: 'Apellido Paterno', align: 'start', key: 'persona.apellido_p', sortable: false },
                { title: 'Apellido Materno', align: 'start', key: 'persona.apellido_m', sortable: false },
                { title: 'DNI', align: 'start', key: 'persona.dni', sortable: false },
                { title: 'Puesto', align: 'start', key: 'persona.puesto', sortable: false },
                { title: 'Supervisor', align: 'start', key: 'supervisor', sortable: false },
                {
                    title: 'Preguntas',
                    align: 'center',
                    children: [
                        { title: '1', align: 'center', key: 'p1', sortable: false },
                        { title: '2', align: 'center', key: 'p2', sortable: false },
                        { title: '3', align: 'center', key: 'p3', sortable: false },
                        { title: '4', align: 'center', key: 'p4', sortable: false },
                        { title: '5', align: 'center', key: 'p5', sortable: false },
                        { title: '6', align: 'center', key: 'p6', sortable: false },
                        { title: '7', align: 'center', key: 'p7', sortable: false },
                        { title: '8', align: 'center', key: 'p8', sortable: false },
                        { title: '9', align: 'center', key: 'p9', sortable: false },
                        { title: '10', align: 'center', key: 'p10', sortable: false },
                        { title: '11', align: 'center', key: 'p11', sortable: false },
                        { title: '12', align: 'center', key: 'p12', sortable: false },
                        { title: '13', align: 'center', key: 'p13', sortable: false },
                        { title: '14', align: 'center', key: 'p14', sortable: false },
                        { title: 'Suma', align: 'center', key: 'suma', sortable: false },
                    ],
                },

                { title: 'Fecha', align: 'center', key: 'fecha', sortable: false, width: '20%' },
                { title: 'Hora', align: 'center', key: 'hora', sortable: false, width: '20%' },
            ]
        },
        cuestionarios() {
            return this.$store.state.cuestionarios;
        },
    },
    created() {
        this.cargarData()
    },
    watch: {
        'params.queryNombres'() {
            this.cargarData()
        },
        'params.queryApellidoPaterno'() {
            this.cargarData()
        },
        'params.queryApellidoMaterno'() {
            this.cargarData()
        },
        'params.queryDni'() {
            this.cargarData()
        },
        'params.queryPuesto'() {
            this.cargarData()
        },
        'params.querySuma'() {
            this.cargarData()
        },
        'params.querySupervisor'() {
            this.cargarData()
        },
        'params.queryFechaInicio'() {
            this.cargarData()
        },
        'params.queryFechaFinal'() {
            this.cargarData()
        },
    },
}
</script>